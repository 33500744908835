import {inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {IgnoreNullHttpParams} from '../shared/Ignore-null-http-params';
import {CertificateGroup, Page} from '../../generated-model/model';
import {environment} from '../../environments/environment';
import {CertificateDto} from '../../generated-model/model';

@Injectable({providedIn: "root"})
export class CertificateRest {
  http = inject(HttpClient);

  search(param: IgnoreNullHttpParams) {
    return this.http.get<Page<CertificateDto>>(`${environment.serverUrl}/api/certificate/search`, {params: param.toHttpParam()})
  }

  save(item: CertificateDto) {
    return this.http.post(`${environment.serverUrl}/api/certificate/save`, item)
  }

  searchAutoComplete(term: string, groups: CertificateGroup[]) {
    return this.http.get<CertificateDto[]>(`${environment.serverUrl}/api/certificate/autocomplete`, {
      params: IgnoreNullHttpParams.fromObject({
        term: term,
        groups: groups
      }).toHttpParam()
    })
  }

  findAndSaveCertFromThaiTraces(){
    return this.http.get(`${environment.serverUrl}/api/certificate/thai-traces`)
  }
}
